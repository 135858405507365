import { useRoutes, useNavigate } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth'

// routes
import MainRoutes from './MainRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';
import config from 'config';
import { auth } from 'firebase';
import { useEffect } from 'react';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    return useRoutes([MainRoutes, AuthenticationRoutes], config.basename);
}
