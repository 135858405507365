import { createContext, useContext } from "react";
import { supabase } from "supabaseClient";


const SupabaseContext = createContext();

export function useSupabase() {
    return useContext(SupabaseContext);
}

export function SupabaseProvider({ children }) {

    const getAllBookings = async (inst_id) => {
        const { data, error } = await supabase
            .from('bookings')
            .select('*')
            .eq('status', 'booked')
            .eq('instructor_id', inst_id)
            .select()
        if (error) {
            console.log('error', error)
        }

        return data
    }

    const getBookingsByDay = async (date, inst_id) => {
        const startOfDay = date.startOf('day');
        const endOfDay = date.endOf('day');
        const { data, error } = await supabase
            .from('bookings')
            .select('*')
            .eq('status', 'booked')
            .lte('booking_time', endOfDay)
            .gte('booking_time', startOfDay)
            .eq('instructor_id', inst_id)
            .select()
            .order('booking_time', { ascending: true })
        if (error) {
            console.log('error', error)
        }
        return data
    }

    const value = {
        getAllBookings,
        getBookingsByDay,
    }

    return (
        <SupabaseContext.Provider value={value}>
            {children}
        </SupabaseContext.Provider>
    )
};