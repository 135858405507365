// assets
import { IconNotebook, IconReportMedical } from '@tabler/icons';

// constant
const icons = {
    IconNotebook,
    IconReportMedical
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const pages = {
    id: 'splashInfo',
    title: 'Splash Info',
    type: 'group',
    children: [
        {
            id: 'contact',
            title: 'Contact Info',
            type: 'item',
            url: '/contact',
            icon: icons.IconNotebook,
        },
        {
            id: 'accidentReport',
            title: 'Incident Report',
            type: 'item',
            url: '/incident-report',
            icon: icons.IconReportMedical,
        },
        {
            id: 'swimmerProgress',
            title: 'Swimmer progress',
            type: 'item',
            url: '/swimmer-progress',
            icon: icons.IconReportMedical,
        }
    ]
};

export default pages;
