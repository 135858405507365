import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import { Navigate } from 'react-router';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));

// utilities routing
const UtilsTypography = Loadable(lazy(() => import('views/utilities/Typography')));
const UtilsColor = Loadable(lazy(() => import('views/utilities/Color')));
const UtilsShadow = Loadable(lazy(() => import('views/utilities/Shadow')));
const UtilsMaterialIcons = Loadable(lazy(() => import('views/utilities/MaterialIcons')));
const UtilsTablerIcons = Loadable(lazy(() => import('views/utilities/TablerIcons')));

// sample page routing
const SamplePage = Loadable(lazy(() => import('views/sample-page')));
const ContactPage = Loadable(lazy(() => import('views/pages/contact')));
const FullSchedulePage = Loadable(lazy(() => import('views/pages/fullSchedule')));
const IncidentReportPage = Loadable(lazy(() => import('views/pages/incidentReport')));
const TodaysLessonsPage = Loadable(lazy(() => import('views/pages/todaysLesson')));
const SwimmerProgressPage = Loadable(lazy(() => import('views/pages/swimmerProgress')));

// ==============================|| MAIN ROUTING ||============================== //
const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/',
            element: <TodaysLessonsPage />
        },
        {
            path: '/full-schedule',
            element: <FullSchedulePage />
        },
        {
            path: '/contact',
            element: <ContactPage />
        },
        {
            path: '/incident-report',
            element: <IncidentReportPage />
        },
        {
            path: '/sample-page',
            element: <SamplePage />
        },
        {
            path: '/swimmer-progress',
            element: <SwimmerProgressPage />
        }
    ]
};

export default MainRoutes;
