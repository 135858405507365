import { auth, db } from "firebase.js";
import {
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import { collection, getDocs, query, where } from "firebase/firestore";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  // const logInWithEmailAndPassword = async (email, password) => {
  //   try {
  //     await signInWithEmailAndPassword(auth, email, password).then((user) => {
  //       console.log("logged in");
  //       navigate("/", { replace: true });
  //     });
  //   } catch (error) {
  //     setError(error);
  //     console.log(error);
  //   }
  // };

  const logout = () => {
    signOut(auth);
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  const value = {
    currentUser,
    logInWithEmailAndPassword,
    logout,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}

const logInWithEmailAndPassword = async (email, password) => {
  try {
    // Check if the user exists in the instructors collection
    const usersCollectionRef = collection(db, 'instructors');
    const querySnapshot = await getDocs(
      query(usersCollectionRef, where('email', '==', email))
    );

    if (querySnapshot.empty) {
      // The user does not exist in the instructors collection
      console.log('User does not exist in the collection');
      // Handle the appropriate action, such as showing an error message
      return;
    }

    // User exists in the instructors collection
    const userDoc = querySnapshot.docs[0];
    const userData = userDoc.data();
    const userRole = userData.role;

    if (userRole === 'instructor' || userRole === 'admin') {
      // Proceed with sign in
      await signInWithEmailAndPassword(auth, email, password);
      console.log('User authenticated as an instructor');
      // Navigate to the desired page after successful login
      navigate('/', { replace: true });
    } else {
      console.log('User is not authorized as an instructor');
      // Handle the appropriate action, such as showing an error message
    }
  } catch (error) {
    console.log(error);
  }
};
// const logInWithEmailAndPassword = async (email, password) => {
//   try {
//     await signInWithEmailAndPassword(auth, email, password).then((user) => {
//       console.log("logged in");
//       navigate("/", { replace: true });
//     });
//   } catch (error) {
//     // setError(error);
//     console.log(error);
//   }
// };


const logout = () => {
  signOut(auth);
};

export { logInWithEmailAndPassword, logout };
