import {
  doc,
  getDoc,
  setDoc,
  updateDoc
} from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { createContext, useContext } from "react";
import { gql, useMutation } from "urql";
import functions, { db } from "../firebase";
const SwimmerContext = createContext();

export function useSwimmer() {
  return useContext(SwimmerContext);
}

export function SwimmerProvider({ children }) {
  const ADD_SWIMMER_CONTEXT = gql`
    mutation ($userId: String!, $swimmerData: String!) {
      reports(input: { userId: $userId, swimmerData: $swimmerData }) {
        id
      }
    }
  `;

  const [swimmerContextData, addSwimmerContextData] =
    useMutation(ADD_SWIMMER_CONTEXT);

  const addSwimmerData = async (data, id) => {
    // console.log("swimmer data :-", data,"id",id);
    const userId = id;
    const swimmerData = data;

    addSwimmerContextData({ userId, swimmerData });

    const docRef = doc(db, "reports", userId);
    console.log("docRef", docRef);
    await setDoc(docRef, {
      userId: userId,
      swimmerData: swimmerData,
    })
      .then((setUser) => {
        console.log("Data has been entered Succesfully");
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };

  const updateSwimmerData = async (data, id) => {
    const userId = id;
    const swimmerData = data;

    addSwimmerContextData({ userId, swimmerData });

    const docRef = doc(db, "reports", userId);
    // console.log("docRef", docRef);
    await updateDoc(docRef, {
      swimmerData: swimmerData,
    })
      .then(() => {
        console.log("Data has been updated successfully");
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };

  const getSwimmerDetailsById = async (id) => {
    try {
      const docRef = doc(db, "reports", id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const data = docSnap.data();
        console.log("Swimmer details:", data);
        return data;
      } else {
        console.log("Swimmer Report with ID", id, "does not exist");
        return null;
      }
    } catch (error) {
      console.error("Error retrieving swimmer details:", error);
      throw error;
    }
  };

  // const getAllSwimmersByName = async () => {
  //   const usersRef = collection(db, "users");
  //   const querySnapshot = await getDocs(usersRef);
  //   const fetchedSwimmers = [];

  //   await Promise.all(
  //     querySnapshot.docs.map(async (doc) => {
  //       const user = doc.data();
  //       const swimmerRef = collection(doc.ref, "swimmers");
  //       const swimmerSnapshot = await getDocs(swimmerRef);

  //       swimmerSnapshot.forEach((swimmerDoc) => {
  //         const swimmerId = swimmerDoc.id;
  //         const swimmerData = swimmerDoc.data();
  //         fetchedSwimmers.push({
  //           id: swimmerId,
  //           name: `${swimmerData.firstName} ${swimmerData.lastName}`,
  //         });
  //       });
  //     })
  //   );

  //   // console.log("Fetched Swimmers Final Data", fetchedSwimmers);
  //   return fetchedSwimmers;
  // };

  // Get a reference to the Firebase function
  

  // Function to get swimmers by name

  const getAllSwimmersByName = async () => {
    try {
      const getSwimmersByName = httpsCallable(functions,"getAllSwimmersByName");
      getSwimmersByName().then(result => {
      console.log(result,"result")
      const swimmersNames = result.data;
      console.log(swimmersNames); // Do something with the swimmers' names
    })
    } catch (error) {
      console.error("Error retrieving swimmers:", error);
    }
  };

  const value = {
    addSwimmerData,
    updateSwimmerData,
    getSwimmerDetailsById,
    getAllSwimmersByName,
  };

  return (
    <SwimmerContext.Provider value={value}>{children}</SwimmerContext.Provider>
  );
}
