import { useSelector } from 'react-redux';

import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';

// routing
import Routes from 'routes';

// defaultTheme
import themes from 'themes';

// project imports
import NavigationScroll from 'layout/NavigationScroll';
import { isLoaded } from 'react-redux-firebase';
import { useEffect } from 'react';
import { AuthProvider, useAuth } from 'hooks/AuthContext';
import { useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useNavigate } from 'react-router';
import { auth } from 'firebase.js';
import Loader from 'ui-component/Loader';
import { createClient, Provider, cacheExchange, fetchExchange, subscriptionExchange, debugExchange } from 'urql';
import { SwimmerProvider } from './hooks/SwimmerContext'
import { SupabaseProvider } from 'hooks/SupabaseContext';
// ==============================|| APP ||============================== //

const App = () => {
    const customization = useSelector((state) => state.customization);
    const [loggedIn, setLoggedIn] = useState(false);

    const [user, loading, error] = useAuthState(auth);
    const navigate = useNavigate();

    useEffect(() => {
        if (loading) {
            return;
        }
        if (!user) navigate('/login');
        console.log(user);
        if (user) navigate('/');
    }, [user, loading])

    const client = createClient({
        url: process.env.REACT_APP_GRAPHQL_ENDPOINT,
        exchanges: [
            fetchExchange,
            cacheExchange,
            subscriptionExchange({
                forwardSubscription(operation) {
                    const url = new URL(process.env.REACT_APP_GRAPHQL_ENDPOINT)
                    url.searchParams.append('query', operation.query)
                    if (operation.variables) {
                        url.searchParams.append(
                            'variables',
                            JSON.stringify(operation.variables)
                        )
                    }
                    return {
                        subscribe(sink) {
                            const eventsource = new EventSource(url.toString(), {
                                withCredentials: true // This is required for cookies
                            })
                            eventsource.onmessage = (event) => {
                                const data = JSON.parse(event.data)
                                sink.next(data)
                                if (eventsource.readyState === 2) {
                                    sink.complete()
                                }
                            }
                            eventsource.onerror = (error) => {
                                sink.error(error)
                            }
                            eventsource.addEventListener('complete', () => {
                                eventsource.close() // If operation ends, close the connection and prevent the client from reconnecting
                            })
                            return {
                                unsubscribe: () => eventsource.close()
                            }
                        }
                    }
                }
            })
        ],
    });

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themes(customization)}>
                <Provider value={client}>
                    <AuthProvider>
                <SupabaseProvider>
                    <SwimmerProvider>
                        <CssBaseline />
                        <NavigationScroll>
                            {loading ?
                                <Loader />
                                :
                                <Routes />
                            }
                        </NavigationScroll>
                    </SwimmerProvider>
                    </SupabaseProvider>
                    </AuthProvider>
                </Provider>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default App;
